.link {
  text-decoration: none;
  display: contents;
}

.block {
  position: relative;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  max-width: 1000px;
  justify-content: center;
}

.flag {
  position: absolute;
  left: 30px;
  top: -2px;
  z-index: 1;
}

.flagImg {
  max-width: 80px;
  height: 90px;
}

.flagText {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.corner {
  position: absolute;
  max-width: 70px;
  &:nth-child(5) {
    left: 0;
    top: 0;
  }
  &:nth-child(2) {
    right: 0;
    top: 0;
    transform: rotate(90deg);
  }
  &:nth-child(3) {
    left: 0;
    bottom: 0;
    transform: rotate(270deg);
  }
  &:nth-child(4) {
    right: 0;
    bottom: 0;
    transform: rotate(180deg);
  }
}
