.wrapper {
  width: 100%;
  max-width: 1000px;
  min-width: 300px;
}

.mainInfo {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
  gap: 70px;
}

.mainInfoAvatar {
  position: absolute;
  left: 50%;
  top: 28%;
  transform: translate(-50%, -50%);
}

.statsLine {
  display: flex;
  justify-content: space-between;
}

.rankText {
  font-family: zombie;
  color: #d5f96f;
  font-size: 50px;
}

.userName {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.rank {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.statsBlock {
  display: flex;
  justify-content: center;
  width: 50%;
  border-bottom: 2px solid #534d3f;
  padding: 10px;
  &:first-child {
    border-right: 2px solid #534d3f;
  }
}

.avatarContainer {
  position: relative;
}

.avatarWrapper {
  width: 200px;
}

.avatar {
  max-width: 66px;
  position: absolute;
  left: 50%;
  top: 4.5%;
  transform: translateX(-50%);
}
