.wrapper {
  box-shadow: none !important;
  background-color: transparent !important;
  background-image: none !important;
  position: fixed !important;
  @media screen and (max-width: 900px) {
    padding: 15px !important;
  }
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  border-radius: 999px;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(24px);
  min-height: 70px;
  height: 70px;
  border: 1px solid #c4ff4d;
  box-shadow: 0 0 1px rgba(2, 31, 59, 0.7),
    1px 1.5px 2px -1px rgba(2, 31, 59, 0.65),
    4px 4px 12px -2.5px rgba(2, 31, 59, 0.65);
}

.left {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-left: -6px;
}

.logo {
  width: 220px;
  height: auto;
  cursor: pointer;
  margin-right: 8px;
}

.container {
  padding: 0 !important;
}

.links {
  display: flex;
  @media screen and (max-width: 900px) {
    display: none;
  }
}

.link {
  color: #fff !important;
}

.routeLink {
  text-decoration: none;
  color: #fff;
}

.signInButton {
  @media screen and (max-width: 900px) {
    display: none !important;
  }
}

.drawer {
  min-width: 60dvw;
  padding: 15px;
}

.drawerSignIn {
  width: 100%;
}

.drawerLinks {
  margin-bottom: 10px;
}

.drawerWrapper {
  @media screen and (min-width: 900px) {
    display: none;
  }
}
