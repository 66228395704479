.copyright {
  display: flex;
  flex-direction: row;
  gap: 1px;
}

.text {
  color: #94a6b8;
}

.link {
  color: #94a6b8 !important;
  margin-bottom: 5px !important;
}

.icon {
  margin-right: 5px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  max-width: 1000px !important;
  width: 100%;
}

.header {
  font-weight: 600 !important;
  margin-bottom: 10px;
}

.row {
  display: flex;
  gap: 80px;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.column {
  display: flex;
  flex-direction: column;
}
